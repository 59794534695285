import request from './request.js';
import public_request from './public_request';
// 个性化设置名称
export function loginTitle(data) {
    return request({
        url: '/setting/style/login-title',
        method: 'put',
        data: public_request(data),
    });
}
// 获取个性化设置
export function styleInfo() {
    return request({
        url: '/setting/style-info',
        method: 'get',
        params: public_request(),
    });
}
