<template>
    <div class="style-setting-page">
        <el-card style="height:100%">
            <el-form ref="form" :model="form" label-position="top">
                <el-form-item
                    label="登录标题名称:"
                    prop="loginTitle"
                    :rules="[
            { required: true, message: '登录标题不能为空' },
            {
              min: 4,
              max: 10,
              message: '长度在 4 到 10 个字符',
              trigger: 'blur'
            }
          ]"
                >
                    <el-input
                        v-model="form.loginTitle"
                        style="width:300px"
                        placeholder="请输入登录标题名称"
                    />
                </el-form-item>
                <!-- :on-success="handleLogoSuccess"
                :before-upload="beforeLogoUpload"-->
                <el-form-item label="企业logo:">
                    <div class="flex-sb">
                        <el-upload
                            ref="logoUrl"
                            :auto-upload="false"
                            :action="logoUrl"
                            :show-file-list="false"
                            :headers="myHeaders"
                            :on-change="logoChange"
                            :on-success="handleLogoSuccess"
                        >
                            <img v-if="form.logoImg" :src="form.logoImg" class="avatar" />
                            <i v-else class="el-icon-circle-plus avatar-uploader-icon" />
                        </el-upload>
                        <div
                            style="font-size:16px;line-height: 1;height: 80px;display: flex;align-items: center;margin-left:10px;color:#aaa"
                        >
                            1.格式为jpg/png
                            <br />2.大小不能超过200kb
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="登录背景图">
                    <div class="flex-sb">
                        <el-upload
                            :action="backGroundUrl"
                            :show-file-list="false"
                            :on-success="handleBackGroundSuccess"
                            :before-upload="beforeBackgroundUpload"
                            :headers="myHeaders"
                        >
                            <img
                                v-if="form.backGroundImg"
                                :src="form.backGroundImg"
                                class="bg-avatar"
                            />
                            <i v-else class="el-icon-circle-plus bg-avatar-uploader-icon" />
                        </el-upload>
                        <div
                            style="font-size:16px;line-height: 1;height: 180px;display: flex;align-items: center;margin-left:10px;color:#aaa"
                        >
                            1.格式为jpg
                            <br />2.尺寸大于等于1920x1080像素
                            <br />3.大小不超过1M
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </el-card>
        <div style="position: relative;bottom: 60px;left: 194px;">
            <el-button type="primary" @click="saveSetting">保存</el-button>
            <el-button @click="cancel">取消</el-button>
        </div>

        <el-dialog center title="图片剪裁" :visible.sync="dialogVisible" width="800px" append-to-body>
            <div style="display:flex;align-itens:center">
                <div class="cropper" style="text-align:center">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :output-size="option.outputSize"
                        :output-type="option.outputType"
                        :info="option.info"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :fixed="option.fixed"
                        :fixed-box="option.fixedBox"
                        :fixed-number="option.fixedNumber"
                        @realTime="realTime"
                    />
                </div>
                <div style="margin-left:20px;">
                    <div style="margin:5px">预览</div>
                    <div
                        class="show-preview"
                        :style="{
              width: '200px',
              height: '65px',
              overflow: 'hidden',
              margin: '5px'
            }"
                    >
                        <div :style="logoInfo.div" class="preview">
                            <img :src="logoInfo.url" :style="logoInfo.img" />
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitLogo">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { VueCropper } from 'vue-cropper';
import { loginTitle, styleInfo } from '@/api/style_setting';
let token = sessionStorage.getItem('token');
import axios from 'axios';
export default {
    components: {
        VueCropper,
    },
    data() {
        return {
            form: {
                loginTitle: '',
                logoImg: '',
                backGroundImg: '',
            },
            // 裁剪配置
            option: {
                img: '', // 裁剪图片的地址
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                full: true, // 是否输出原图比例的截图
                info: true, // 图片大小信息
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 200, // 默认生成截图框宽度
                autoCropHeight: 65, // 默认生成截图框高度
                canMove: true, // 上传图片是否可以移动
                fixedBox: true, // 固定截图框大小 不允许改变
                fixed: false, // 是否开启截图框宽高固定比例
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                height: true,
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                enlarge: 1, // 图片根据截图框输出比例倍数
                mode: 'container', // 图片默认渲染方式
                maxImgSize: 375, // 限制图片最大宽度和高度
            },
            logoUrl: '',
            backGroundUrl: '',
            myHeaders: { Authorization: token },
            logoInfo: '',
            dialogVisible: false,
            settingInfo: {},
        };
    },
    created() {
        (this.logoUrl = process.env.VUE_APP_URL + '/setting/style/logo'),
            (this.backGroundUrl =
                process.env.VUE_APP_URL + '/setting/style/login-background');
        this.getStyleSetting();
    },
    mounted() {},
    methods: {
        // 获取数据
        getStyleSetting() {
            styleInfo().then((res) => {
                if (res.code == 0) {
                    this.settingInfo = res.data;
                    this.form.loginTitle = res.data.loginTitle;
                    this.form.logoImg = res.data.logoImageUrl;
                    this.form.backGroundImg = res.data.loginBackgroundImageUrl;
                }
            });
        },
        logoChange(file) {
            let types = ['image/jpeg', 'image/jpg', 'image/png'];
            const isImage = types.includes(file.raw.type);
            const isRightSize = file.size / 1024 < 200;
            if (!isImage) {
                this.$message.error('企业logo图片格式为JPG、PNG 格式!');
            } else if (!isRightSize) {
                this.$message.error('企业logo大小不能超过200k');
            } else if (file.status == 'ready') {
                this.$nextTick(() => {
                    this.option.img = URL.createObjectURL(file.raw);
                    this.dialogVisible = true;
                });
            }
        },
        handleLogoSuccess(res) {
            if (res.code == 0) {
                this.form.logoImg = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
        //上传logo之前的操作
        // beforeLogoUpload(file) {
        //   // 1.格式为jpg/png
        //   // 2.大小不超过200kb
        // },
        // 处理背景图片上传
        handleBackGroundSuccess(res) {
            if (res.code == 0) {
                this.form.backGroundImg = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
        beforeBackgroundUpload(file) {
            let types = ['image/jpeg', 'image/jpg'];
            const isImage = types.includes(file.type);
            const isLt1M = file.size / 1024 / 1024 < 1;

            if (!isImage) {
                this.$message.error('登陆背景图只能是 JPG 格式!');
            } else if (!isLt1M) {
                this.$message.error('登陆背景图大小不能超过1MB!');
            }
            const isSize = new Promise(function (resolve, reject) {
                let width = 1920; // 限制图片尺寸为654X270
                let height = 1080;
                let _URL = window.URL || window.webkitURL;
                let img = new Image();
                img.onload = function () {
                    let valid = img.width >= width && img.height >= height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$message.error('背景尺寸为1920×1080像素');
                    return Promise.reject();
                }
            );
            return isImage && isLt1M && isSize;
        },
        // 保存设置
        saveSetting() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let data = {
                        loginTitle: this.form.loginTitle,
                    };
                    loginTitle(data).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('上传成功');
                            this.getStyleSetting();
                            // location.reload();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 预览
        realTime(data) {
            this.logoInfo = data;
        },
        submitLogo() {
            this.dialogVisible = false;
            this.$refs.cropper.getCropData((data) => {
                let blob = this.base64ToBlob(data);
                let file = new File([blob], 'logo.jpg', {
                    type: blob.type,
                    lastModified: Date.now(),
                });
                let formdata = new FormData();
                formdata.append('file', file);
                let baseUrl = process.env.VUE_APP_URL;
                let config = {
                    headers: {
                        Authorization: sessionStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data',
                    },
                };
                axios
                    .post(baseUrl + '/setting/style/logo', formdata, config)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.form.logoImg = res.data.data;
                        }
                    });
            });
            // this.$refs.logoUrl.submit();
        },
        // 点击取消
        cancel() {
            // this.form.loginTitle = this.settingInfo.loginTitle
            //   ? this.settingInfo.loginTitle
            //   : "";
            // this.form.logoImg = this.settingInfo.logoImageUrl
            //   ? this.settingInfo.logoImageUrl
            //   : "";
            // this.form.backGroundImg = this.settingInfo.loginBackgroundImageUrl
            //   ? this.settingInfo.loginBackgroundImageUrl
            //   : "";
            this.form.loginTitle = '';
            this.form.logoImg = '';
            this.form.backGroundImg = '';
        },
        base64ToBlob(dataurl) {
            let arr = dataurl.split(',');
            //注意base64的最后面中括号和引号是不转译的
            let _arr = arr[1].substring(0, arr[1].length - 2);
            let mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(_arr),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], {
                type: mime,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.flex-sb {
    display: flex;
    // align-items: center;
}
.style-setting-page {
    height: 100%;
    padding: 10px;
    overflow: hidden;
    .el-card {
        height: 100%;
        /deep/ .el-card__body {
            overflow-y: scroll;
            height: 84%;
        }
    }
}
/deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
/deep/ .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #4086ec;
    width: 300px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}
.avatar {
    width: 300px;
    height: 80px;
    display: block;
}
.bg-avatar-uploader-icon {
    font-size: 28px;
    color: #4086ec;
    width: 300px;
    height: 168px;
    line-height: 168px;
    text-align: center;
}
.bg-avatar {
    width: 300px;
    height: 168px;
    display: block;
}
/deep/ .el-form--label-top .el-form-item__label {
    padding: 0;
}
.cropper-content {
    width: 500px;
    height: 350px;
    background: pink;
}
.cropper {
    width: 400px;
    height: 400px;
    background: yellow;
}
/deep/ .el-form-item__content {
    text-align: left !important;
}
</style>
